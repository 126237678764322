import { MainLayout } from 'components/layout';
import React, { useEffect } from 'react';
import { Header } from '../components/Header';

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <MainLayout headerContent={<Header start="PrivacyPoli" tail="cy" />}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">TickerZone Privacy Policy</h1>
        <p className="mb-4">Last Updated: May 15th, 2023</p>
        <p>
          This privacy policy (“Privacy Policy”) is intended to provide you with
          specific details about how TickerZone (“TickerZone,” “we,” and “us”)
          collects and processes your personal and personally identifiable
          information through your use of the TickerZone Website and associated
          services including, but not limited to, the tickerzone desktop and
          mobile application (“Services”). TickerZone is the data processor and
          data controller of all information collected through the Services.
        </p>

        <p className="mt-4">
          You understand and agree that TickerZone may store and process your
          personal information on computers located outside of the European
          Union, including, but not limited to, in the United States. By using
          the Services, you agree to the collection and processing of your
          personal or personally identifiable information outside of the
          European Union.
        </p>

        <p className="mt-4">
          Before using the Services or providing information to us, please
          carefully review this Privacy Policy. By using or accessing the
          Services, you agree that we may collect and use your personal and
          personally identifiable information in accordance with this Privacy
          Policy, as revised from time to time. TickerZone may modify, amend,
          replace, or suspend this Privacy Policy at any time. If you have any
          questions or suggestions regarding our Privacy Policy, or if your
          personal information is not accurate or complete, please contact us
          at:{' '}
          <a href="mailto:support@tickerzone.com" className="text-blue-500">
            support@tickerzone.com
          </a>
        </p>

        <p className="mt-4">
          By providing TickerZone with your personal or personally identifiable
          data and using the Services, you warrant that you are over the age of
          eighteen (18) or otherwise above the age of majority within your
          jurisdiction. If you are younger than eighteen (18) or the age of
          majority within your jurisdiction, please do not use the TickerZone
          Services and please do not provide personal information to us.
        </p>

        <p className="mt-4">Personal Information Collected.</p>

        <p className="mt-4">
          When you use the Services, we may collect personal or personally
          identifiable information from you (“PII”). PII may include any
          information that identifies, relates to, describes, references, is
          reasonably capable of being associated with, or could reasonably be
          linked, directly or indirectly, with a particular individual. It does
          not include anonymized data.
        </p>

        <p className="mt-4">
          We may collect and process the following categories of PII about you:
        </p>

        <ul className="list-disc list-inside ml-8 mt-4">
          <li className="mt-2">
            Communication Data. Communication data includes any communication
            that you may send to us through the Services, email, or social
            media. We process this data to communicate with you, to provide you
            with customer support, to record logs of our communication, and to
            store information to respond to legal claims. TickerZone’s lawful
            ground for processing this communication data is to respond to
            communications sent by you to us, to keep records of our
            communication, and to pursue or defend against legal claims.
          </li>
          <li className="mt-2">
            User Data. User data includes data about how you use the Services
            and any data that you post to or authorize through the Services.
            This includes data stored in persistent cookies when you login to
            the Services and analytics data that is collected when you use the
            Services. We collect and process this data to operate the Services,
            to authenticate you as a user of the Services, to ensure that timely
            and relevant content is provided to you, to secure the Services, to
            ensure that the Services operate in a fast and efficient manner, and
            to maintain backups of the Services. TickerZone’s lawful ground for
            processing this user data is its legitimate business interests in
            administering and offering the Services.
          </li>
          <li className="mt-2">
            Technical Data. Technical data includes data about your use of the
            Services, such as your IP address, your login data, your phone
            number, your mobile device model, your operating system, your
            geolocation, and your time zone. TickerZone may collect this data
            from your use of the Services and from advertising IDs. TickerZone
            processes this data to analyze your use of the Services, to route
            Services traffic, to administer and secure the Services, to provide
            location-relevant content, and to troubleshoot problems with the
            Services. TickerZone’s lawful ground for collecting and processing
            this technical data is its legitimate interests in administering and
            offering the Services and to grow its business and marketing
            strategy.
          </li>
          <li className="mt-2">
            Marketing Data. Marketing data includes data about your preferences
            in receiving and interacting with TickerZone’s advertisements or
            content on the Services. TickerZone collects this data from your use
            of the Services and from advertising IDs created through advertising
            programs, such as Google Advertising IDs, TikTok Advertising IDs,
            and Apple Advertising IDs. TickerZone does not connect advertising
            identifiers to persistent device identifiers, such as MAC addresses
            or mobile device IDs. TickerZone’s lawful ground for collecting and
            processing this marketing data is its legitimate interests in
            administering and offering the Services and to grow its business and
            marketing strategy by providing advertisements, including
            remarketing advertisements, to you.
          </li>
          <li className="mt-2">
            Financial Information. If you decide to link financial accounts that
            you hold with a financial institution (“Financial Account”) to your
            User Account, we will collect certain information from you about
            your Financial Account, such as your online login credentials, your
            transaction information, amount of funds, and other information
            about you and your Financial Account (“Financial Information”).
            TickerZone will use your Financial Information to provide you the
            Services. TickerZone’s lawful ground for collecting Financial
            Information is your consent, which is given when you link your
            Financial Accounts to your TickerZone User Accounts.
          </li>
          <li className="mt-2">
            Personally Sensitive Data. Personally sensitive data includes data
            about your race or ethnicity, religious or philosophical beliefs,
            sex life, sexual orientation, political opinions, union membership,
            or information about your health. TickerZone does not collect or use
            personally sensitive data through the Services.
          </li>
        </ul>

        <p className="mt-4">
          TickerZone will only use your PII for the purpose for which it was
          collected. In the event that TickerZone needs to use your PII for an
          unrelated new purpose, TickerZone will provide you with notice of this
          new use and will explain the lawful ground for such processing.
          TickerZone may process your PII without your knowledge or consent
          where required or permitted by law.
        </p>

        <p className="mt-4">
          TickerZone does not use your PII to automatically make any decisions
          or to create an automated profile about you.
        </p>

        <p className="mt-4">Collection of Data From Third Parties.</p>

        <p className="mt-4">
          TickerZone may collect data about you through cookies and other
          technologies. This helps TickerZone understand how you use the
          Services and to understand any patterns that may be associated with
          your use of the Services. This aids TickerZone in developing or
          improving its Services in response to your needs or wants.
        </p>

        <p className="mt-4">
          TickerZone may use session or persistent cookies. Session cookies are
          only stored on your computer or mobile device during your use of the
          Services and are automatically deleted when you close your web
          browser. Session cookies may be used to direct internet traffic to a
          server that is closer to you or to allow us to identify you as you
          move between pages of the Services. Persistent cookies are stored as a
          file on your computer or mobile device that remains on your computer
          or mobile device even after you close your web browser. Persistent
          cookies can be read by the Services that created the cookie when you
          revisit it again. TickerZone may use persistent cookies to
          authenticate you when you return to the Services so that you do not
          have to login again or when it utilizes Google Analytics or other
          analytics providers, which is intended to track the origin and
          behavior of traffic to the Services. TickerZone may use the following
          analytics providers, and you are directed to review their cookie
          policies:
        </p>

        <ul className="list-disc list-inside ml-8 mt-4">
          <li className="mt-2">
            <a
              href="https://policies.google.com/technologies/ads"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Google Ads
            </a>
          </li>
          <li className="mt-2">
            <a
              href="https://help.hotjar.com/hc/en-us/articles/115011789248-Cookies-on-hotjar-com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Hotjar
            </a>
          </li>
          <li className="mt-2">
            <a
              href="https://www.rudderstack.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Rudderstack
            </a>
          </li>
        </ul>

        <p className="mt-4">
          TickerZone may also receive data from third parties including
          analytics providers or advertising networks such as Google, Facebook,
          TikTok, or Apple. These third parties may be based outside of the
          European Union.
        </p>

        <p className="mt-4">Marketing Communications.</p>

        <p className="mt-4">
          As stated above, TickerZone’s lawful ground for sending you marketing
          communications is either consent or its legitimate business interests,
          such as to grow its business by advertising products to you.
          TickerZone may send you marketing communications if you have asked for
          information concerning its goods or services or if you have agreed to,
          and have not opted out from, receiving marketing communications. You
          may ask TickerZone to stop sending you marketing messages at any time
          by logging into the Services to adjust your marketing preferences
          within your User Account or by following the opt-out link in any
          marketing message sent to you. If you opt-out of receiving marketing
          communications, your opt-out does not extend to PII provided for other
          purposes.
        </p>

        <p className="mt-4">Disclosure of Personal Information.</p>

        <p className="mt-4">
          TickerZone may share your PII with the following parties:
        </p>

        <ul className="list-disc list-inside ml-8 mt-4">
          <li className="mt-2">
            Service providers that provide TickerZone with information
            technology or other administrative services;
          </li>
          <li className="mt-2">
            TickerZone’s accountants, auditors, insurers, or attorneys;
          </li>
          <li className="mt-2">
            Law enforcement agencies upon the receipt of a subpoena or court
            order or where necessary to protect TickerZone’s personnel or
            property; and
          </li>
          <li className="mt-2">
            Government bodies that require TickerZone to report its processing
            activities.
          </li>
        </ul>

        <p className="mt-4">
          TickerZone may also transfer PII to third parties when it sells,
          transfers, or merges any part of its business or assets.
        </p>

        <p className="mt-4">
          TickerZone requires all third parties that receive a transfer of PII
          from TickerZone to maintain the same level of respect for the
          protection of PII as TickerZone and TickerZone only allows third
          parties to process your personal data for the specific purposes listed
          in this Privacy Policy.
        </p>

        <p className="mt-4">Data Security.</p>

        <p className="mt-4">
          TickerZone has put into place data security measures to protect your
          PII. TickerZone allows access to your PII only by employees and
          service providers who have a need to know or on TickerZone’
          instructions. However, no security measures are impenetrable and there
          are always security risks. TickerZone will notify you and any
          regulatory body of any breach of your PII or TickerZone’s security
          measures if it is legally required to do so.
        </p>

        <p className="mt-4">Retention of Data.</p>

        <p className="mt-4">
          TickerZone will only retain your PII for so long as necessary to
          fulfill the purposes for which it is collected under this Privacy
          Policy or for the purposes of satisfying any legal, accounting, or
          reporting requirements. We will also retain and use your PII to the
          extent necessary to comply with our legal obligations (for example, if
          we are required to retain your data to comply with applicable laws,
          such as the California Consumer Privacy Act), resolve disputes, and
          enforce our legal agreements and policies.
        </p>

        <p className="mt-4">
          Right to Access, Rectification, Correction, Erasure, Transfer, and
          Withdrawal.
        </p>

        <p className="mt-4">
          As a consumer, you have the right to request access to your personally
          identifiable information (PII) for rectification, correction, erasure,
          transfer, or restriction, or to object to its processing or withdraw
          your consent. If you wish to exercise any of these rights, please
          contact our Data Protection Officer as disclosed in this Privacy
          Policy.
        </p>

        <p className="mt-4">
          We do not charge a fee for accessing your PII or exercising your
          rights. However, we may charge a reasonable fee if your request is
          unreasonable or excessive.
        </p>

        <p className="mt-4">
          To ensure the security of your PII, we may need to request specific
          information from you to confirm your request. We strive to respond to
          any verifiable consumer request within forty-five (45) days of
          receipt.
        </p>

        <p className="mt-4">
          If we require more time, we will inform you of the reason and
          extension period in writing. We will deliver our written response by
          mail or electronically, at your option. Our response will also explain
          the reasons we cannot comply with a request, if applicable.
        </p>

        <p className="mt-4">
          In the case of data portability requests, we will select a format to
          provide your personal information that is readily usable and should
          allow you to transmit the information.
        </p>

        <p className="mt-4">
          If we modify or delete some or all of your personal information, we
          will retain your personal data if reasonably necessary to fulfill any
          other requests from you (for example, to opt-out of further messages
          or for a copy of your data).
        </p>

        <p className="mt-4">California Privacy Rights.</p>

        <p className="mt-4">
          The following rules apply solely to visitors, users, and others who
          are residents of the State of California. California residents have
          the right to be notified which categories of PII are being collected
          and the purposes for which the PII is being used. In particular, we
          collected the following categories of PII (A, B, D, and F) as defined
          in the California Consumer Privacy Act within the last twelve months.
          Our uses of this PII are detailed above in this Privacy Policy:
        </p>

        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-100 px-4 py-2">
                Category
              </th>
              <th className="border border-gray-300 bg-gray-100 px-4 py-2">
                Examples
              </th>
              <th className="border border-gray-300 bg-gray-100 px-4 py-2">
                Collected
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                A. Identifiers
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, or other similar identifiers.
              </td>
              <td className="border border-gray-300 px-4 py-2">YES</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                B. Personal information categories listed in the California
                Consumer Records statute (Cal. Civ. Code § 1798.80(e))
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Name, signature, address, telephone number, bank account number,
                credit card number, debit card number, or any other financial
                information.
              </td>
              <td className="border border-gray-300 px-4 py-2">YES</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                D. Commercial
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Records of products purchased.
              </td>
              <td className="border border-gray-300 px-4 py-2">YES</td>
            </tr>
          </tbody>
        </table>

        <p className="mt-8">
          We obtain these categories of PII directly from California residents
          when they complete forms through our Services or provide it to us as a
          part of a transaction or inquiry concerning our products. We also
          obtain these categories of PII indirectly from California residents
          while observing their actions on our Services and from third parties
          or service providers that they have authorized to receive and share
          PII.
        </p>

        <p className="mt-4">
          California residents have a right to request that we disclose what PII
          we collect from you and whether, and how, we disclose or sell that
          PII. California residents may also request that we delete any personal
          information collected or maintained by us from you.
        </p>

        <p className="mt-4">
          Even though TickerZone does not sell your personal information, you
          may still see interest-based advertisements. To learn more about
          interest-based advertising across websites and additional opt-out
          choices, you can visit{' '}
          <a href="http://optout.aboutads.info" className="text-blue-500">
            http://optout.aboutads.info
          </a>
          . If you opt-out of the sale of your personal information but do not
          opt out of interest-based advertising more generally, you may still
          receive ads tailored to your interests based on PII that was not sold
          by us, personal information that was sold to downstream participants
          at least 90 days before you opted out, or personal information that
          was sold by other sources from which you have not opted out.
        </p>

        <p className="mt-4">
          To submit a request for a list of the categories of personal
          information collected from you or to request that TickerZone delete
          your personal information, please email us at:{' '}
          <a href="mailto:support@tickerzone.com" className="text-blue-500">
            support@tickerzone.com
          </a>
        </p>

        <p className="mt-4">
          To verify your request, we may request certain information from you to
          confirm that you are a TickerZone user, such as your phone number,
          username, email address, city, state, or geographic location. You may
          also designate an authorized agent to make a request to TickerZone to
          disclose or delete your personal information. To do so, you must
          provide TickerZone with proof that the individual or business has been
          appointed as your agent, such by providing a signed power of attorney
          form, and provide accurate responses to any information requested by
          TickerZone that may be necessary to confirm that you are a TickerZone
          user, such as your phone number, username, email address, city, state,
          or geographic location. California residents have a right not to
          receive discriminatory treatment by TickerZone for their exercise of
          these rights conferred under California law.
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">Third Party Links</h2>

        <p className="mt-4">
          TickerZone’ Services may include links to third-party websites and
          applications. By clicking on third-party links, you may allow third
          parties to collect or share data about you. TickerZone does not
          control these third-party links and you are advised to review their
          respective privacy policies.
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">
          Responding to Do Not Track Signals
        </h2>

        <p className="mt-4">
          You can generally opt-out of receiving personalized ads from third
          party advertisers and ad networks who are members of the Network
          Advertising Initiative (NAI) or who follow the Digital Advertising
          Alliance’s Self-Regulatory Principles for Online Behavioral
          Advertising by visiting the opt-out pages on the NAI website and DAA
          website. The TickerZone Website is not currently set up to respond to
          browser do-not-track signals because there is no consensus within the
          advertising industry as to what “do not track” means in this context,
          but you can configure your browser settings to reject all cookies or
          prompt you before a cookie is set.
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">Nevada Residents</h2>

        <p className="mt-4">
          If you are a resident of Nevada, you may provide notice to us to limit
          the sale of your PII to third parties for resale or licensing
          purposes. However, we do not sell your PII for such use. To notify us
          that you wish to limit the sale of your PII to third parties for
          resale or licensing purposes, you may send us an email{' '}
          <a href="mailto:support@tickerzone.com" className="text-blue-500">
            support@tickerzone.com
          </a>{' '}
          with the subject line, “Nevada Do Not Sell Request,” along with your
          name, address, and user account information.
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">Children’s Privacy</h2>

        <p className="mt-4">
          We do not direct the Services to anyone under the age of 13 and we do
          not knowingly collect PII from anyone under the age of 13. If you are
          a parent or guardian of an individual under the age of 13 and believe
          that we may have collected PII from your child, please contact us at{' '}
          <a href="mailto:support@tickerzone.com" className="text-blue-500">
            support@tickerzone.com
          </a>
          .
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">Notification of Changes</h2>

        <p className="mt-4">
          Whenever TickerZone changes its Privacy Policy, we will post those
          changes to this Privacy Policy on the TickerZone Website and other
          places that we deem appropriate. Your use of the TickerZone Services
          following these changes indicates your consent to the practices
          described in the revised Privacy Policy.
        </p>

        <h2 className="text-lg font-bold mt-8 mb-4">Contact Us</h2>

        <p className="mt-4">
          If you have any questions about this Privacy Policy or the manner by
          which we collect or use Personal Information about you, email us at{' '}
          <a href="mailto:support@tickerzone.com" className="text-blue-500">
            support@tickerzone.com
          </a>
          .
        </p>
      </div>
    </MainLayout>
  );
};
