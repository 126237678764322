import React from 'react';

export const Header = ({ start, tail }) => {
  return (
    <div className="text-white text-3xl md:text-8xl font-bold mx-8 md:mx-28 py-12">
      {start}
      <u className="underline decoration-4 underline-offset-[15px] decoration-[#FE5F00]">
        {tail}.
      </u>
    </div>
  );
};
