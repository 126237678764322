import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const sizes = {
  sm: 'h-4 w-4',
  md: 'h-8 w-8',
  lg: 'h-16 w-16',
  xl: 'h-24 w-24',
};

const variants = {
  light: 'text-white',
  primary: 'text-blue-600',
};

export const Spinner = ({
  size = 'md',
  variant = 'primary',
  className = '',
  constraint,
}) => {
  const [color, setColor] = useState('#FE5F00');

  useEffect(() => {
    const interval = setInterval(() => {
      setColor(prevColor => (prevColor === '#FE5F00' ? '#4A4A68' : '#FE5F00'));
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {constraint === 'TICKERZONE' ? (
        <>
          <svg
            className={clsx(sizes[size], variants[variant], className)}
            xmlns="http://www.w3.org/2000/svg"
            width="413"
            height="473"
            viewBox="0 0 413 473"
            fill={color}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M401.058 148.783V143.154H330.067V202.055L401.058 148.783ZM220.932 266.296V98.2945H370.875V87.5949C383.496 86.8431 393.335 83.153 400.394 76.5246C408.798 68.6082 413 57.6133 413 43.5398C413 29.4664 408.798 18.6913 400.394 11.2148C392.433 3.73827 380.933 0 365.894 0C350.414 0 338.471 3.73827 330.067 11.2148C323.265 17.6028 319.368 26.3988 318.377 37.6026H0V98.2945H149.942V266.296H220.932ZM149.942 292.684H220.932V473H149.942V292.684ZM330.067 235.114L401.058 181.842V473H330.067V235.114Z"
            />
          </svg>
          <span className="sr-only">Loading</span>
        </>
      ) : (
        <>
          <svg
            className={clsx(
              'animate-spin',
              sizes[size],
              variants[variant],
              className
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            data-testid="loading"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="sr-only">Loading</span>
        </>
      )}
    </>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf(['light', 'primary']),
  className: PropTypes.string,
};
