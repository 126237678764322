import { MainLayout } from 'components/layout';
import React, { useEffect } from 'react';
import { Header } from '../components/Header';

export const License = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <MainLayout headerContent={<Header start="License Agreeme" tail="nt" />}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-4">
          TickerZone Mobile Application
        </h1>
        <h2 className="text-xl font-bold mb-4">
          End User License Agreement and Terms of Use
        </h2>
        <p className="mb-4">Last Updated: May 15, 2023</p>
        <p className="mb-8">
          These are the End User License Agreement and Terms of Use (the
          "Terms") by and between You, as the herein defined "User" and us,
          TickerZone, INC ("TickerZone"," "we," "us", or "our"). These Terms
          govern your use and access to the TickerZone mobile application
          including any ancillary services and products, tools, features, or
          functionalities provided on or in connection with our mobile
          application (collectively, the "Service" or the "Services"). The term
          of this Agreement will begin when you first access and use the
          Services and will continue until the earlier of the following: (i)
          termination pursuant to this Agreement or (ii) you cease using the
          Services.
        </p>
        <p className="mb-8">
          If you used the TickerZone Website to use, download, purchase, or
          otherwise access the Service, you are also bound by the Terms of Use
          of the TickerZone Website. To the extent and terms in the Terms of Use
          of the TickerZone Website conflict with the Terms herein, the Terms
          herein control.
        </p>
        <p className="mb-8">
          PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT
          INFORMATION AND AFFECT YOUR LEGAL RIGHTS. THEY INCLUDE A MANDATORY
          ARBITRATION AGREEMENT AND CLASS ACTION WAIVER WHICH (WITH LIMITED
          EXCEPTIONS) REQUIRE ANY DISPUTES BETWEEN US TO BE RESOLVED THROUGH
          INDIVIDUAL ARBITRATION RATHER THAN BY A JUDGE OR JURY IN COURT.
        </p>
        <p className="mb-8">
          BY CLICKING TO ACCEPT AND/OR OTHERWISE USING OUR SERVICES, YOU AGREE
          TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY
          REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR
          USE THE SERVICES.
        </p>
        <h2 className="text-2xl font-bold mb-4">
          Before using our services, the following context is hereby
          acknowledged and agreed by you:
        </h2>
        <p className="mb-8">
          Use of the Service is explicitly conditioned on your compliance with
          these Terms and accordingly we reserve the right to take action, with
          or without advance notice, if we believe you have violated these
          Terms.
        </p>
        <p className="mb-8">
          TickerZone is not a financial institution, exchange, money services
          business, or creditor. TickerZone is not an "investment adviser" as
          defined in the federal Investment Advisers Act of 1940. TickerZone is
          not a "broker" or a "dealer" as those terms are defined under the
          federal Securities Exchange Act of 1934. TickerZone is not a financial
          planning advisory service of any kind. TickerZone provides a desktop
          and mobile application that allows Users to control and analyze their
          own financial information and positions with or without the assistance
          of third-party financial advisors. TickerZone does not provide users
          with tax, legal, financial planning, insurance, accounting,
          investment, or any other kind of professional advice or services, and
          nothing found within, provided by, or related to the Service should be
          construed as an offer to sell, a solicitation of an offer to buy, or a
          recommendation for any purchase decision, security, insurance policy,
          or investment strategy. Nothing provided by TickerZone within its
          Service should be used as a substitute for the individually tailored
          advice that can be provided by a licensed professional. You agree to
          be solely responsible for your use of Service and any information
          available through Services.
        </p>
        <p className="mb-8">
          TickerZone reserves the right to change or modify these Terms at any
          time and in our sole discretion. If we make material changes to these
          Terms, we will use reasonable efforts to provide notice of such
          changes, such as by providing notice through the Service. By
          continuing to access or use the Service, you confirm your acceptance
          of the revised Terms and all of the terms incorporated therein by
          reference effective as of the date these Terms are updated. It is your
          sole responsibility to review the Terms from time to time to view such
          changes and to ensure that you understand the terms and conditions
          that apply when you access or use the Service.
        </p>

        <h2 className="text-2xl font-bold mb-4">
          Section 1: License and Restrictions
        </h2>

        <p className="mb-8">
          1.1. Ownership, In General. The Service, including its "look and feel"
          (e.g., text, graphics, images, logos, page headers, button icons, and
          scripts), proprietary content, information and other materials, and
          all content and other materials contained therein, including, without
          limitation, the TickerZone logo and all designs, text, graphics,
          pictures, data, software, sound files, other files, and the selection
          and arrangement thereof are the proprietary property of TickerZone or
          our affiliates, licensors, or users, as applicable. You agree not to
          take any action(s) inconsistent with such ownership interests. We and
          our affiliates, licensors, and users, as applicable, reserve all
          rights in connection with the Service and its content, including,
          without limitation, the exclusive right to create derivative works.
        </p>

        <p className="mb-8">
          1.2. Our Intellectual Property. TickerZone's name, logo, trademarks,
          and any product or service names, designs, logos, and slogans are the
          intellectual property of TickerZone or our affiliates or licensors and
          may not be copied, imitated or used, in whole or in part, without our
          prior written permission in each instance. All other third-party
          trademarks, registered trademarks, and product names mentioned on the
          Service or contained in the content linked to or associated with the
          Service are the property of their respective owners and may not be
          copied, imitated or used, in whole or in part, without the permission
          of the applicable intellectual property rights holder. Reference to
          any products, services, processes, or other information by name,
          trademark, manufacturer, supplier, or otherwise does not constitute or
          imply endorsement, sponsorship, or recommendation by TickerZone.
        </p>

        <p className="mb-8">
          1.3. License. We hereby grant you a limited, individual,
          non-exclusive, non-sublicensable, non-assignable, non-transferable,
          fee-required license to download, install and use the Service on your
          computer or mobile device. This license does not grant you any right
          to any version enhancement or update, or guarantee the continuous
          availability of the Service. If any software, content, or other
          materials owned by, controlled by, or licensed to us are distributed
          or made available to you as part of your use of the Service, we hereby
          grant you a non-commercial, personal, non-assignable,
          non-sublicensable, non-transferable, revocable, and non-exclusive
          right and license to access and display such software, content, and
          materials provided to you as part of the Service, in each case for the
          sole purpose of enabling you to use the Service as permitted by these
          Terms. We may revise, modify, cease to provide, or require you to
          immediately stop using the Service at our sole discretion, from time
          to time, without prior notice to you. Use of the License is expressly
          conditioned on your acceptance of and compliance with the applicable
          Terms of Use, which are hereby incorporated into this EULA by
          reference.
        </p>

        <p className="mb-8">
          1.4. License Conditions and Restrictions. As a condition of the
          license granted to you under the terms of this Agreement, you are
          expressly prohibited from the following:
        </p>
        <ol className="list-decimal ml-8 mb-8">
          <li>
            Distributing, framing, emulating, cloning, publishing, displaying,
            selling, assigning, sublicensing, renting, leasing, loaning,
            modifying, publicly displaying, publicly performing, creating
            derivative works of, translating, or adapting the Service;
          </li>
          <li>
            Decompiling, reverse engineering, disassembling, or hacking the
            Service;
          </li>
          <li>Scraping, caching, or utilizing the Service through a proxy;</li>
          <li>
            Unless otherwise allowed under a group license, granting more than
            one user access to Service;
          </li>
          <li>
            Using documents downloaded from the Service after the expiration or
            termination your User Account;
          </li>
          <li>Circumventing the Service's technology protection measures;</li>
          <li>
            Infringing upon the intellectual property or other proprietary
            rights of TickerZone;
          </li>
          <li>
            Exporting the Service in violation of the export controls or
            regulations of the laws of your country or any other country's laws
            or regulations;
          </li>
          <li>
            Using the Service to violate any applicable law, statute,
            regulation, ordinance, or treaty, whether national or international;
          </li>
          <li>
            Using the Service to violate the rights of third parties, including,
            but not limited to, personal and proprietary rights; and
          </li>
          <li>
            Removing or altering any proprietary notices contained within the
            Service, including, but not limited to, copyright and trademark
            notices.
          </li>
        </ol>

        <p className="mb-8">
          1.5. No Right to Unlimited Access or Use. Your access and use of the
          Service may be interrupted from time to time for any of several
          reasons, including, without limitation, the malfunction of equipment,
          periodic updating, maintenance, or repair of the Service or other
          actions that we, in our sole discretion, may elect to take.
        </p>

        <p className="mb-8">
          1.6. License Grant to Us. By using the Service in conjunction with
          creating, submitting, posting, promoting, or displaying content, you
          grant us a worldwide, non-exclusive, sublicensable, royalty-free
          license to use, copy, modify, and display any content, including but
          not limited to text, materials, images, files, communications,
          comments, feedback, suggestions, ideas, concepts, questions, data, or
          otherwise, that you submit or post on or through the Service for our
          current and future business purposes, including to provide, promote,
          and improve the Service.
        </p>

        <p className="mb-8">
          1.7. Denial of Access. TickerZone reserves the right to terminate your
          access to and use of the Service, in whole or in part, at any time,
          with or without notice, at TickerZone's discretion for any or no
          reason. TickerZone reserves the right at all times to disclose any
          information as it deems necessary to satisfy any applicable law,
          regulation, legal process, or governmental request, or to edit, refuse
          to post or to remove any information or materials, in whole or in
          part, at our sole discretion.
        </p>

        <p className="mb-8">
          1.8. Feedback. If you choose to provide input and suggestions
          regarding problems with or proposed modifications or improvements to
          the Service ("Feedback"), then you hereby grant TickerZone an
          unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
          royalty-free right to exploit the Feedback in any manner and for any
          purpose, including to improve the Service and create other products
          and services.
        </p>

        <h2 className="text-2xl font-bold mb-4">Section 2: User Accounts</h2>

        <p className="mb-8">
          <span className="font-bold">2.1 Creating a User Account.</span> To
          access the Service, you must create an individualized user account
          ("User Account"). When registering for an account, TickerZone will
          require you to provide certain contact and personal details. Providing
          false, incorrect, or outdated information may prevent you from
          registering and impair TickerZone’s ability to provide you with the
          Service or to contact you. TickerZone will indicate the fields that
          are mandatory to complete, and if you do not enter the requisite data
          in these fields, you will not be able to register for a User Account.
          You agree we may use such information in accordance with our Privacy
          Policy.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.2 Account Security; No Liability.</span>{' '}
          Your use of the Service may allow you to link your User Account to
          other accounts you own that are held by third parties (“Third-Party
          Accounts”). Third-Party Accounts are not operated by, maintained by,
          or affiliated with TickerZone, and TickerZone does not have custody or
          control over the contents of your Third-Party Accounts and has no
          ability to retrieve or transfer contents from within those accounts.
          TickerZone accepts no responsibility for, or liability to you, in
          connection with your use of Third-Party Accounts and makes no
          representations or warranties regarding how the Service will operate
          when linked to any specific Third-Party Account. You are solely
          responsible for keeping your Third-Party Accounts secure and you
          should never share your Third-Party Account credentials or security
          details with anyone. You are solely responsible for your User Account
          and any linked or otherwise associated Third-Party Accounts and we are
          not liable for any acts or omissions by you in connection with your
          User Account or as a result of your User Account or Third-Party
          Accounts being compromised. You agree to immediately notify us if you
          discover or otherwise suspect any security issues related to the
          Service or your User Account. TickerZone has no control over, and
          assumes no responsibility for the payment, terms of services, privacy
          policies, or practices of, any Third-Party Account holder. We strongly
          recommend you read the terms and conditions and privacy policy of each
          Third-Party Account holder’s website.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.3 Communication Preferences.</span> By
          creating a User Account or otherwise providing us with contact methods
          through the use of the Service, you consent to receive electronic
          communications from us (e.g., via email, push notification, text
          messages, or other types of messages). These communications may
          include notices about your User Account (e.g., transactional
          information) and are part of your relationship with us. We may also
          send you promotional communications via email we think will be of
          interest to you. You understand that you are not required to provide
          this consent as a condition of using the Service and you may opt out
          of these communications through the Service or through your mobile
          device’s operating system (with the possible exception of important
          service announcements and administrative messages) by following the
          unsubscribe instructions.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.4 Payments.</span> When you register for
          a User Account to access and use the Service, or after the expiration
          of a promotional offer for a free trial period where applicable, you
          will be charged a subscription fee of $74.99 for one year’s use of the
          Service. TickerZone offers a grace period whereby if you are not
          satisfied with the Service, you may request a refund within thirty
          (30) days of purchasing a subscription. Other than requested refunds
          within the grace period, the subscription fee is non-refundable. More
          details regarding subscriptions to the Service can be found{' '}
          <a href="subscription_details.html" className="text-blue-500">
            here
          </a>
          .
        </p>

        <p className="mb-8">
          <span className="font-bold">2.5 Taxes.</span> You are solely
          responsible for assessing all Taxes owed, if any, for your use of the
          Service. As used herein, “Taxes” means the taxes, duties, levies,
          tariffs, and other governmental charges that you may be required to
          collect and remit to governmental agencies, and other similar
          municipal, state, federal, national or other withholding and personal
          or corporate income taxes.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.6 Termination.</span> If you breach any
          of the provisions of these Terms, all licenses granted by TickerZone
          will terminate automatically. Additionally, notwithstanding anything
          contained in these Terms, we reserve the right, with or without notice
          and in our sole discretion, to suspend, disable, terminate, or delete
          your User Account and/or your ability to access or use the Service (or
          any part of the foregoing) at any time and for any or no reason, and
          you acknowledge and agree that we shall have no liability or
          obligation to you in such event and that you will not be entitled to a
          refund of any amounts that you have already paid to us.
        </p>

        <p className="mb-8">
          <span className="font-bold">
            2.7 Third-Party Content and Services.
          </span>{' '}
          Through your use of the Service, you may explore content created by
          third parties. TickerZone does not make any representations or
          warranties about this third-party content visible through our Service,
          including any content associated with the Service, and you bear
          responsibility for verifying the legitimacy, authenticity, and
          legality of any third parties and their content.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.8 Third Party Websites.</span> The
          Service may also contain links or functionality to access or use
          third-party websites (“Third-Party Websites”) and applications
          (“Third-Party Applications”), or otherwise display, include, or make
          available content, data, information, services, applications, or
          materials from third parties (“Third-Party Materials”). When you click
          on a link to, or access and use, a Third-Party Website or Third-Party
          Application, though we may not warn you that you have left our
          Service, you are subject to the terms and conditions (including
          privacy policies) of another website or destination. Such Third-Party
          Websites, Third-Party Applications, and Third-Party Materials are not
          under the control of TickerZone, and may be “open” applications for
          which no recourse is possible. TickerZone is not responsible or liable
          for any Third-Party Websites, Third-Party Applications, and
          Third-Party Materials. TickerZone provides links to these Third-Party
          Websites and Third-Party Applications only as a convenience and does
          not review, approve, monitor, endorse, warrant, or make any
          representations with respect to Third-Party Websites or Third-Party
          Applications, or their products or services or associated Third-Party
          Materials. You use all links in Third-Party Websites, Third-Party
          Applications, and Third-Party Materials at your own risk.
        </p>

        <p className="mb-8">
          <span className="font-bold">2.9 Privacy Policy.</span> Please refer to
          our Privacy Policy, which is hereby incorporated into these Terms by
          reference, for information about how we collect, use, and share
          personal data about you. By submitting personal data through our
          Service, you agree to the terms of our Privacy Policy and you
          expressly consent to the collection, use, and disclosure of your
          personal data in accordance with the Privacy Policy.
        </p>

        <section className="py-8">
          <h2 className="text-2xl font-bold mb-4">
            Section 3: User Warranties
          </h2>

          <p className="mb-8">
            <span className="font-bold">3.1 Age of Majority.</span> All users
            must be at least 18 years old. If you are at least 13 years old but
            under 18 years old, you may only use the Service through a parent or
            guardian’s User Account and with their approval and oversight. That
            account holder is responsible for your actions using the User
            Account. It is prohibited to use our Service if you are under 13
            years old.
          </p>

          <p className="mb-8">
            <span className="font-bold">
              3.2 Compliance; Embargoed and Sanctioned Jurisdictions.
            </span>{' '}
            You represent and warrant that you will comply with all applicable
            laws, including without limitation all local, state, federal and
            other laws, when using the Service. You further agree that you will
            not violate any contract, intellectual property or other third-party
            right, and that you are solely responsible for your conduct and
            content, while accessing or using the Service. In addition, this
            means, without limiting the generality of the foregoing, that by
            using the Service, you represent and warrant that: (a) you are not
            located in, ordinarily resident in, or organized under the laws of
            any jurisdiction that is subject to a comprehensive U.S. Government
            embargo ("Embargoed Jurisdiction"); (b) you are not subject to any
            sanctions administered by an agency of the U.S. Government, any
            other government, or the United Nations (collectively, “Sanctions”);
            (c) you are not owned or controlled, directly or indirectly, by any
            person that is subject to Sanctions, or that is located in,
            ordinarily resident in, or organized under the laws of, any
            Embargoed Jurisdiction; (d) none of your officers, managers,
            directors, shareholders or authorized representatives is subject to
            Sanctions, or is located in, ordinarily resident in, or organized
            under the laws of, any Embargoed Jurisdiction, or is owned or
            controlled, directly or indirectly, by any person that is subject to
            Sanctions or that is located in, ordinarily resident in, or
            organized under the laws of, any Embargoed Jurisdiction. You further
            covenant that the foregoing shall be true during the entire period
            of this agreement. If you access or use the Service outside the
            United States, you are solely responsible for ensuring that your
            access and use of the Service in such country, territory or
            jurisdiction does not violate any applicable laws.
          </p>

          <p className="mb-8">
            <span className="font-bold">3.3 Cooperation.</span> TickerZone may
            require you to provide additional information and documents in
            certain circumstances, such as at the request of any government
            authority, as any applicable law or regulation dictates, or to
            investigate a potential violation of these Terms. In such cases, we,
            in our sole discretion, may disable your User Account and block your
            ability to access the Service until such additional information and
            documents are processed by us. If you do not provide complete and
            accurate information in response to such a request, we may refuse to
            restore your access to the Service.
          </p>

          <p className="mb-8">
            <span className="font-bold">3.4 User Code of Conduct.</span> In
            addition to all other terms or conditions contained in these Terms,
            you will not under any circumstances:
          </p>
          <ol className="list-decimal pl-8 mb-8">
            <li>
              Use or attempt to use another user’s Account without authorization
              from such user; pose as another person or entity;
            </li>
            <li>
              Claim a username for the purpose of reselling it, confusing
              others, deriving others’ goodwill, or otherwise engage in name
              squatting;
            </li>
            <li>
              Create or display assets or other items that promote suicide or
              self-harm, incites hate or violence against others, or doxes
              another individual;
            </li>
            <li>
              Distribute spam, including through sending unwanted communications
              or assets to other users;
            </li>
            <li>
              Use the Service in any manner whatsoever if such use, in
              TickerZone’s sole discretion, could damage, disable, overburden,
              or impair the functioning of the Service in any manner;
            </li>
            <li>
              Bypass or ignore instructions that control access to the Service,
              including attempting to circumvent any systems of the Service
              through use of multiple API keys, directing traffic through
              multiple IP addresses, or otherwise obfuscating the source of
              traffic you send to TickerZone;
            </li>
            <li>
              Use our Service for commercial purposes inconsistent with these
              Terms or any other instructions;
            </li>
            <li>
              Use any data mining, robot, spider, crawler, scraper, script,
              browser extension, offline reader, or other automated means or
              interface not authorized by us to access the Service, extract
              data, or otherwise interfere with or modify the rendering of
              Service pages or functionality;
            </li>
            <li>
              Reverse engineer, duplicate, decompile, disassemble, or decode any
              aspect of the Service, or do anything that might discover source
              code or bypass or circumvent measures employed to prevent or limit
              access to any service, area, or code of the Service;
            </li>
            <li>
              Sell or resell the Service or attempt to circumvent any of our fee
              systems;
            </li>
            <li>
              Engage in behaviors that have the intention or the effect of
              artificially causing an item or collection to appear at the top of
              search results, or artificially increasing view counts, favorites,
              or other metrics that we might use to sort search results;
            </li>
            <li>
              Use the Service or data collected from our Service for any
              advertising or direct marketing activity (including without
              limitation, email marketing, SMS marketing, and telemarketing);
            </li>
            <li>
              Use the Service for or in connection with money laundering,
              terrorist financing, or other illicit financial activity, or in
              any way in connection with the violation of any law or regulation
              that applies to you or to TickerZone;
            </li>
            <li>
              Use the Service, directly or indirectly, for, on behalf of, or for
              the benefit of, (a) any natural or legal person that is the
              subject of Sanctions; (b) any natural or legal person located in,
              ordinarily resident in, or organized under the laws of, any
              Embargoed Jurisdiction; or (c) any legal person owned or
              controlled, directly or indirectly, by any natural or legal person
              located in, ordinarily resident in, or organized under the laws
              of, any Embargoed Jurisdiction;
            </li>
            <li>
              Use the Service to carry out any financial activities subject to
              registration or licensing, including but not limited to creating,
              offering, selling, or buying securities, commodities, options, or
              debt instruments, including without limitation the use of the
              Service to give owners rights to participate in an ICO or any
              securities offering, or that are redeemable for securities,
              commodities, or other financial instruments;
            </li>
            <li>
              Use the Service to engage in price manipulation, fraud, or other
              deceptive, misleading, or manipulative activity;
            </li>
            <li>
              Use the Service to buy, sell, or transfer stolen items,
              fraudulently obtained items, items taken without authorization,
              and/or any other illegally obtained items;
            </li>
            <li>
              Infringe or violate the intellectual property rights or any other
              rights of others;
            </li>
            <li>
              Create or display illegal content, such as content that may
              involve child sexual exploitation;
            </li>
            <li>
              Use the Service for any illegal or unauthorized purpose, including
              creating or displaying illegal content, or encouraging or
              promoting any activity that violates the Terms of Service;
            </li>
            <li>
              Use the Service in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying the
              Service.
            </li>
          </ol>

          <p className="mb-8">
            <span className="font-bold">3.5 Rights, Title and Authority.</span>{' '}
            In addition to and in conjunction with the license grants you make
            to us under this Agreement, you represent and warrant that you have,
            or have obtained, all rights, licenses, consents, permissions, power
            and/or authority necessary to grant the rights granted herein for
            any content that you create, submit, post, promote, or display on or
            through the Service. You represent and warrant that such content
            does not contain material subject to copyright, trademark, publicity
            rights, or other intellectual property rights, unless you have
            necessary permission or are otherwise legally entitled to post the
            material and to grant us the license described below, and that the
            content does not violate any laws.
          </p>

          <p className="mb-8">
            <span className="font-bold">3.8 Non-Reliance.</span> You acknowledge
            and agree that you have conducted your own thorough independent
            investigation and analysis of the Service and the other matters
            contemplated by these Terms, and have not relied upon any
            information, statement, omission, representation, or warranty,
            express or implied, written or oral, made by or on behalf of
            TickerZone in connection therewith, except as expressly set forth by
            us in these Terms.
          </p>
        </section>

        <section className="py-8">
          <h2 className="text-2xl font-bold mb-4">
            Section 5: Indemnification
          </h2>

          <p className="mb-8">
            <span className="font-bold">5.1 Indemnification</span> By agreeing
            to these Terms and accessing the Service, you agree, to the fullest
            extent permitted by applicable law, to indemnify, defend, and hold
            harmless TickerZone, and our respective past, present, and future
            employees, officers, directors, contractors, consultants, equity
            holders, suppliers, vendors, service providers, parent companies,
            subsidiaries, affiliates, agents, representatives, predecessors,
            successors, and assigns (individually and collectively, the
            “TickerZone Parties”), from and against all actual or alleged
            claims, damages, awards, judgments, losses, liabilities,
            obligations, taxes, penalties, interest, fees, expenses (including,
            without limitation, attorneys’ fees and expenses), and costs
            (including, without limitation, court costs, costs of settlement,
            and costs of pursuing indemnification and insurance), of every kind
            and nature whatsoever, whether known or unknown, foreseen or
            unforeseen, matured or unmatured, or suspected or unsuspected, in
            law or equity, whether in tort, contract, or otherwise
            (collectively, “Claims”), including, but not limited to, damages to
            property or personal injury, that are caused by, arise out of, or
            are related to (a) your use or misuse of the Service, content, or
            content linked to or associated with any Third-Party Accounts, (b)
            your violation or breach of any term of these Terms or applicable
            law, and (c) your violation of the rights of or obligations to a
            third party, including another user or third-party, and (d) your
            negligence or willful misconduct. You agree to promptly notify
            TickerZone of any Claims and cooperate with the TickerZone Parties
            in defending such Claims. You further agree that the TickerZone
            Parties shall have control of the defense or settlement of any
            Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY
            OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND
            TickerZone.
          </p>
        </section>

        <section className="py-8">
          <h2 className="text-2xl font-bold mb-4">Section 6: Disclaimers</h2>

          <p className="mb-8">
            <span className="font-bold">
              6.1 Use of Service is at Your Own Risk.
            </span>{' '}
            Your access to and use of the Service is at your own risk. You
            understand and agree that the Service is provided on an "as is" and
            "as available" basis and TickerZone expressly disclaims warranties
            or conditions of any kind, either express or implied. TickerZone
            makes no warranty or representation and disclaims all responsibility
            for whether the Service: (a) will meet your requirements; (b) will
            be available on an uninterrupted, timely, secure, or error-free
            basis; or (c) will be accurate, reliable, complete, legal, or safe.
            TickerZone disclaims all other warranties or conditions, express or
            implied, including, without limitation, implied warranties or
            conditions of merchantability, fitness for a particular purpose,
            title, and non-infringement. TickerZone will not be liable for any
            loss of any kind from any action taken or taken in reliance on
            material or information, contained on the Service. While TickerZone
            attempts to make your access to and use of the Service safe and
            secure, TickerZone cannot and does not represent or warrant that the
            Service, content, content linked to or associated with any
            Third-Party Accounts, or our service providers’ servers are free of
            viruses or other harmful components. We cannot guarantee the
            security of any data that you disclose online. No advice or
            information, whether oral or obtained from the TickerZone Parties or
            through the Service, will create any warranty or representation not
            expressly made herein. You accept the inherent security risks of
            providing information and dealing online over the Internet and will
            not hold TickerZone responsible for any breach of security.
          </p>

          <p className="mb-8">
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
            RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF
            THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR
            CLAIMS ARISING FROM: (A) USER ERROR, INCORRECTLY CONSTRUCTED
            TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA
            LOSS; (C) UNAUTHORIZED ACCESS OR USE; (D) ANY UNAUTHORIZED
            THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF
            VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE
            SERVICE.
          </p>

          <p className="mb-8">
            NO TickerZone PARTY IS RESPONSIBLE OR LIABLE FOR ANY SUSTAINED
            LOSSES OR INJURY DUE TO VULNERABILITY OR ANY KIND OF FAILURE OR
            ABNORMAL BEHAVIOR OF SOFTWARE BY OR RELATING TO THIRD-PARTY
            ACCOUNTS.
          </p>

          <p className="mb-8">
            Some jurisdictions do not allow the exclusion of implied warranties
            in contracts with consumers, so the above exclusion may not apply to
            you.
          </p>

          <p className="mb-8">
            <span className="font-bold">6.2 Limitation of Liability.</span> To
            the fullest extent permitted by law, you agree that in no event will
            TickerZone or its service providers be liable to you or any third
            party for any lost profit or any indirect, consequential, exemplary,
            incidental, special, or punitive damages arising from these Terms or
            the Service, products or third-party sites, accounts, and products,
            or for any damages related to loss of revenue, loss of profits, loss
            of business or anticipated savings, loss of use, loss of goodwill,
            or loss of data, and whether caused by strict liability or tort
            (including negligence), breach of contract, or otherwise, even if
            foreseeable and even if TickerZone or its service providers have
            been advised of the possibility of such damages; or for any other
            claim, demand, or damages whatsoever resulting from or arising out
            of or in connection with these Terms of the delivery, use, or
            performance of the Service. Access to, and use of, the Service,
            products or third-party sites, accounts, and products are at your
            own discretion and risk, and you will be solely responsible for any
            damage to your computer system or mobile device or loss of data
            resulting therefrom.
          </p>

          <p className="mb-8">
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
            EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF TickerZone ARISING
            OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO AND USE
            OF THE SERVICE OR ANY TickerZone PRODUCTS OR SERVICES EXCEED THE
            LESSER OF (A) US $500.00 OR (B) THE AMOUNT RECEIVED BY TickerZone
            FOR ITS SERVICE DIRECTLY RELATING TO THE ITEMS THAT ARE THE SUBJECT
            OF THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE
            STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </p>

          <p className="mb-8">
            Some jurisdictions do not allow the exclusion or limitation of
            incidental or consequential damages, so the above limitation or
            exclusion may not apply to you. Some jurisdictions also limit
            disclaimers or limitations of liability for personal injury from
            consumer products, so this limitation may not apply to personal
            injury claims.
          </p>
        </section>

        <section className="py-8">
          <h2 className="text-2xl font-bold mb-4">
            Section 7: Modification of Terms
          </h2>

          <p className="mb-8">
            <span className="font-bold">7.1 Modification of Terms.</span> We
            may, from time to time, change these Terms. Please check these Terms
            periodically for changes. Revisions will be effective immediately
            except that we may require that you accept the modified Terms in
            order to continue to use the Service. If you do not agree to the
            modified Terms, then you should terminate your User Account and
            discontinue your use of the Service. Except as expressly permitted
            in this Section, these Terms may be amended only by a written
            agreement signed by authorized representatives of the parties to
            these Terms. It is your sole responsibility to review the Terms and
            understand modifications to these Terms as they may be updated from
            time to time.
          </p>
        </section>
        <div className="prose">
          <h2 className="text-xl font-bold">SECTION 8: DISPUTE RESOLUTION</h2>

          <p className="mt-4">
            YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
            TickerZone ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE IN A CLASS ACTION.
          </p>

          <h3 className="text-lg font-bold mt-6">
            8.1. Dispute Resolution and Arbitration Agreement
          </h3>
          <p>
            Please read the following arbitration agreement in this Section
            ("Arbitration Agreement") carefully. Disputes shall be resolved in
            accordance with the applicable provisions of the American
            Arbitration Association ("AAA"). It requires you to arbitrate
            disputes with TickerZone and limits the manner in which you can seek
            relief from us. This section does not govern disputes between users
            or between users and third parties. TickerZone does not provide
            dispute resolution services for such disagreements, and the parties
            must resolve those disputes directly. You agree that any dispute,
            controversy, or claim relating in any way to your access or use of
            the Service, to any products sold or distributed through the
            Service, or to any aspect of your relationship with TickerZone, will
            be resolved by binding arbitration, rather than in court, including
            threshold questions of the arbitrability of such dispute,
            controversy, or claim except that:
          </p>
          <ol className="list-decimal list-inside ml-8">
            <li>
              You or TickerZone may assert claims in small claims court, but
              only if the claims qualify, the claims remain only in such court,
              and the claims remain on an individual, non-representative, and
              non-class basis; and
            </li>
            <li>
              You or TickerZone may seek injunctive or equitable relief in a
              court of proper jurisdiction if the claim relates to intellectual
              property infringement or other misuse of intellectual property
              rights.
            </li>
          </ol>

          <h3 className="text-lg font-bold mt-6">
            8.2. Dispute resolution process
          </h3>
          <p>
            You and TickerZone both agree to engage in good-faith efforts to
            resolve disputes prior to either party initiating an arbitration,
            small claims court proceeding, or equitable relief for intellectual
            property infringement. You must initiate this dispute resolution
            process by sending a letter describing the nature of your claim and
            desired resolution to:
          </p>

          <pre className="mt-2 bg-gray-100 p-2">NOTICES FOR ARBITRATION</pre>

          <p className="mt-4">The Notice of Arbitration must:</p>
          <ol className="list-decimal list-inside ml-8">
            <li>Describe the nature and basis of the claim or dispute; and</li>
            <li>Set forth the specific relief sought ("Demand").</li>
          </ol>
          <p>
            The parties will make good faith efforts to resolve the claim
            directly, but if the parties do not reach an agreement to do so
            within 30 days after the Notice of Arbitration is received, you or
            TickerZone may commence an arbitration proceeding. All arbitration
            proceedings between the parties will be confidential unless
            otherwise agreed by the parties in writing. During the arbitration,
            the amount of any settlement offer made by you or TickerZone must
            not be disclosed to the arbitrator until after the arbitrator makes
            a final decision and award, if any. If the arbitrator awards you an
            amount higher than the last written settlement amount offered by
            TickerZone in settlement of the dispute prior to the award,
            TickerZone will pay you the lower of:
          </p>
          <ol className="list-decimal list-inside ml-8">
            <li>The amount awarded by the arbitrator, or</li>
            <li>US $100.</li>
          </ol>

          <h3 className="text-lg font-bold mt-6">8.3. Fees</h3>
          <p>
            Payment of any fees will be decided by the rules of the AAA. Any
            arbitration hearing will take place at a location to be agreed upon
            in the county and state of your residence or billing address, but if
            the claim is for US $1,000 or less, you may choose whether the
            arbitration will be conducted:
          </p>
          <ol className="list-decimal list-inside ml-8">
            <li>
              Solely on the basis of documents submitted to the arbitrator;
            </li>
            <li>Through a nonappearance based telephone hearing; or</li>
            <li>
              By an in-person hearing as established by the AAA Rules in the
              county (or parish) of your residence or billing address.
            </li>
          </ol>
          <p>
            If the arbitrator finds that either the substance of your claim or
            the relief sought in the Demand is frivolous or brought for an
            improper purpose (as measured by the standards set forth in Federal
            Rule of Civil Procedure 11(b)), then the payment of all fees will be
            governed by the AAA Rules. In that case, you agree to reimburse
            TickerZone for all monies previously disbursed by it that are
            otherwise your obligation to pay under the AAA Rules. Regardless of
            the manner in which the arbitration is conducted, the arbitrator
            must issue a reasoned written decision sufficient to explain the
            essential findings and conclusions on which the decision and award,
            if any, are based. The arbitrator may make rulings and resolve
            disputes as to the payment and reimbursement of fees or expenses at
            any time during the proceeding and upon request from either party
            made within 14 days of the arbitrator’s ruling on the merits.
          </p>

          <h3 className="text-lg font-bold mt-6">8.4. Waiver of Jury Trial</h3>
          <p>
            YOU AND TickerZone HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY
            RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A
            JURY.
          </p>

          <h3 className="text-lg font-bold mt-6">
            8.5. Waiver of Class Actions and Class Arbitrations
          </h3>
          <p>
            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A
            REPRESENTATIVE OR COLLECTIVE CLASS BASIS. ONLY INDIVIDUAL RELIEF IS
            AVAILABLE, AND CLAIMS OF MORE THAN ONE USER, PERSON, OR ENTITY
            CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER,
            PERSON, OR ENTITY.
          </p>

          <h2 className="text-xl font-bold mt-8">SECTION 9: MISCELLANEOUS</h2>

          <h3 className="text-lg font-bold mt-6">
            9.1. Governing Law and Venue
          </h3>
          <p>
            These Terms and your access to and use of the Service shall be
            governed by and construed and enforced in accordance with the laws
            of the State of Nevada (without regard to conflict of law rules or
            principles of the State of Nevada, or any other jurisdiction that
            would cause the application of the laws of any other jurisdiction).
            Any dispute between the parties that is not subject to arbitration
            as set forth in Section 8 or cannot be heard in small claims court,
            shall be resolved in the state or federal courts of the State of
            Nevada.
          </p>

          <h3 className="text-lg font-bold mt-6">9.2. Severability</h3>
          <p>
            If any term, clause, or provision of these Terms is held invalid or
            unenforceable, then that term, clause, or provision will be
            severable from these Terms and will not affect the validity or
            enforceability of any remaining part of that term, clause, or
            provision, or any other term, clause, or provision of these Terms.
          </p>

          <h3 className="text-lg font-bold mt-6">9.3. Injunctive Relief</h3>
          <p>
            You agree that a breach of these Terms will cause irreparable injury
            to TickerZone for which monetary damages would not be an adequate
            remedy and TickerZone shall be entitled to equitable relief in
            addition to any remedies it may have hereunder or at law without a
            bond, other security, or proof of damages.
          </p>

          <h3 className="text-lg font-bold mt-6">9.4. California Residents</h3>
          <p>
            If you are a California resident, in accordance with Cal. Civ. Code
            § 1789.3, you may report complaints to the Complaint Assistance Unit
            of the Division of Consumer Services of the California Department of
            Consumer Affairs by contacting them in writing at 1625 North Market
            Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
            952-5210.
          </p>

          <h3 className="text-lg font-bold mt-6">9.5. Survival</h3>
          <p>
            All sections which by their nature should survive the termination of
            these Terms shall continue in full force and effect subsequent to
            and notwithstanding any termination of these Terms by TickerZone or
            you. Termination will not limit any of TickerZone’s other rights or
            remedies at law or in equity.
          </p>

          <h3 className="text-lg font-bold mt-6">9.6. General Terms</h3>
          <p>
            These Terms constitute the entire agreement between you and
            TickerZone relating to your access to and use of the Service. These
            Terms, and any rights and licenses granted hereunder, may not be
            transferred or assigned by you without the prior written consent of
            TickerZone, and TickerZone’s failure to assert any right or
            provision under these Terms shall not constitute a waiver of such
            right or provision. No waiver by either party of any breach or
            default hereunder shall be deemed to be a waiver of any preceding or
            subsequent breach or default. The section headings used herein are
            for reference only and shall not be read to have any legal effect.
          </p>

          <p>
            The Service is operated by us in the United States. Those who choose
            to access the Service from locations outside the United States do so
            at their own initiative and are responsible for compliance with
            applicable local laws. You and TickerZone agree that the United
            Nations Convention on Contracts for the International Sale of Goods
            will not apply to the interpretation or construction of these Terms.
          </p>

          <p>
            Except as otherwise provided herein, these Terms are intended solely
            for the benefit of the parties and are not intended to confer
            third-party beneficiary rights upon any other person or entity.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};
